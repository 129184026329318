import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import styles from '@/styles/Home.module.css'
import Html5QrcodePlugin from '@/components/Html5QrcodePlugin'
import { AppBar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Input, Slide, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography, tableCellClasses } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import { Share } from '@mui/icons-material';
import { colors } from '@/constants/colors'
import React, { useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import axios from 'axios'
import { GetServerSideProps, NextPage } from 'next'
import { toast } from 'react-toastify'
import VerifiedIcon from '@mui/icons-material/Verified';
import { protocol } from '@/utils/frontend'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import eventConfig from "./../../public/event.config.json"

const inter = Inter({ subsets: ['latin'] })

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ariaLabel = { 'aria-label': 'description' };

type Props = {
  host: string;
  event: {
    name: string;
    event_name: string;
    date: string;
    description: string;
    date_start: string;
    date_end: string;
    location: string;
    location_frame_link: string;
    organiser: {
      logo: string;
      name: string;
      website: string;
      description: string
    }
  }
};

const Home: NextPage<Props> = ({ host, event }) => {
  // console.log('event', event);
  const [open, setOpen] = useState(false);
  const [dialogLogin, setOpenDialogLogin] = useState(false);
  const [email, setemail] = useState("");
  const [name, setname] = useState("")
  const [contact, setcontact] = useState("");
  const [loading, setloading] = useState(false)
  const [success, setsuccess] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
    setsuccess(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegister = async () => {
    setloading(true);
    try {
      await axios.post(`${protocol}://${host}/api/register`, {
        email, name, contact
      })
      setsuccess(true);

    } catch (error: any) {
      toast.error(error.response.data.message);
    } finally {
      setloading(false);
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: 600, height: '100vh', position: 'relative', background: '#ffffff' }}>
          <AppBar position="static" color='default'>
            <Toolbar sx={{ padding: '12px 0 12px 20px' }}>
              <Typography variant="h5" sx={{ flexGrow: 1 }} color={colors.BLUE} fontSize={28}>
                {event.name}
              </Typography>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(host)
                  toast.success('Link copied', { position: 'bottom-center' })
                }}
                size="large"
              >
                <img src="/share.svg" alt="" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container style={{ padding: 0, background: '#ffffff' }}>
            <img src="/event-1.png" alt="" style={{ width: '100%' }} />
            <Stack sx={{ padding: '0 20px', overflow: 'auto', marginBottom: '50px' }}>
              <Typography variant='h5' fontSize={20} sx={{ marginTop: '32px' }}>{event.date}</Typography>
              <Typography variant='h3' fontSize={36}>{event.event_name}</Typography>
              <Typography variant='body1' fontSize={20} sx={{ marginTop: '36px' }} component={'span'}>
                {event.description}
              </Typography>
              <Box display={'flex'} sx={{ marginTop: '32px' }}>
                <img src="/calendar.svg" alt="" style={{ backgroundColor: colors.GREY, padding: 14, width: 24, height: 24, marginRight: 18 }} />
                <Stack>
                  <Typography variant='body1' fontSize={22}>Date & Time</Typography>
                  <Typography fontSize={18}>{event.date_start} -</Typography>
                  <Typography fontSize={18}>{event.date_end}</Typography>
                </Stack>
              </Box>
              <Box display={'flex'} sx={{ marginTop: '32px', marginBottom: '20px' }}>
                <LocationOnIcon style={{ backgroundColor: colors.GREY, padding: 14, width: 24, height: 24, marginRight: 18 }} color='primary' />
                {/* <img src="/calendar.svg" alt="" style={{ backgroundColor: colors.GREY, padding: 14, width: 24, height: 24, marginRight: 18 }} /> */}
                <Stack>
                  <Typography variant='body1' fontSize={22}>Location</Typography>
                  <Typography fontSize={18}>{event.location}</Typography>
                </Stack>
              </Box>
              <iframe src={event.location_frame_link} height="450" loading="lazy" style={{ border: 0 }}></iframe>
              {/* <Typography variant='h3' fontSize={36} marginTop={2}>About the organiser</Typography> */}
              <Box sx={{ padding: '32px', display: 'flex', flexDirection: 'column', alignItems: 'center ' }}>
                <img src={event.organiser.logo} alt=""
                  style={{ width: '100px', height: '100px', border: '1px solid #eeeeee', borderRadius: '50%' }} />
                <br />
                Organised by
                <br />
                <Typography>{event.organiser.name}</Typography>
                <Box display={'flex'} sx={{ marginTop: '32px', marginBottom: '20px', cursor: 'pointer' }} onClick={() => window.open(event.organiser.website, "_blank")}>
                  <LanguageIcon style={{ backgroundColor: colors.GREY, padding: 14, width: 24, height: 24 }} color='primary' />
                </Box>
                <Typography textAlign={'justify'}>
                  {event.organiser.description}
                </Typography>
              </Box>
            </Stack>
            <Box sx={{ position: 'sticky', bottom: 0, width: '100%', color: 'white', borderTop: '1px solid rgba(0,0,0,0.2)', background: 'white' }}>
              <Box sx={{ padding: '24px 32px' }}>
                <Button variant='contained' sx={{ color: 'white', borderRadius: 2 }} fullWidth onClick={handleClickOpen}>
                  Register
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth={'xs'}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{ sx: { borderRadius: 4, padding: '20px 32px' } }}
      >
        <DialogTitle sx={{ display: success ? 'none' : 'visible' }}>
          <Typography color={colors.BLUE} fontSize={'24px'} textAlign={'center'}>Registration</Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '20px 12px' }}>
          <Box textAlign={'center'} sx={{ display: success ? 'visible' : 'none' }}>
            <VerifiedIcon style={{ width: 150, height: 150 }} color='success' />
            <Typography>Congratulations, you have successfully registered, please check your email to login</Typography>
          </Box>
          <Box sx={{ display: success ? 'none' : 'visible' }}>
            <Input type='text' disableUnderline={true} placeholder="Name" inputProps={ariaLabel} fullWidth
              onChange={(e) => setname(e.target.value)}
              sx={{
                background: colors.GREY, borderRadius: '10px',
                marginBottom: '12px',
                input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', borderRadius: '10px' }
              }} />

            <Input type='email' disableUnderline={true} placeholder="Email" inputProps={ariaLabel} fullWidth
              onChange={(e) => setemail(e.target.value)}
              sx={{
                background: colors.GREY, borderRadius: '10px',
                marginBottom: '12px',
                input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', borderRadius: '10px' }
              }} />

            <Input type='text' disableUnderline={true} placeholder="Contact" inputProps={ariaLabel} fullWidth
              value={contact}
              onChange={(event) => {
                const re = /^[0-9\b]+$/;

                if (event.target.value === '' || re.test(event.target.value)) {
                  setcontact(event.target.value);
                }
              }}
              sx={{
                background: colors.GREY, borderRadius: '10px',
                marginBottom: '12px',
                input: { textAlign: 'center', paddingTop: '10px', paddingBottom: '10px', borderRadius: '10px' }
              }} />

          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', margin: '0 4px', display: success ? 'none' : 'visible' }}>
          <LoadingButton variant='contained' fullWidth sx={{ borderRadius: 2 }} onClick={handleRegister} loading={loading}>Register</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Home;
export const getServerSideProps: GetServerSideProps<Props> =
  async context => {
    // console.log('config', eventConfig);

    return {
      props: {
        host: context.req.headers.host || "localhost:3000",
        event: eventConfig
      }
    }
  };